import React from "react";
import styled from "styled-components";
import ButtonElement from "../../shared/button-element";

const AppleLogo = () => {
  return(
      <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.7476 5.60547C19.79 4.34082 20.5249 2.61475 20.5249 0.871582C20.5249 0.632324 20.5078 0.393066 20.4736 0.205078C18.7646 0.273438 16.7139 1.33301 15.5005 2.76855C14.5264 3.8623 13.6377 5.60547 13.6377 7.34863C13.6377 7.62207 13.689 7.87842 13.7061 7.96387C13.8086 7.98096 13.9795 8.01514 14.1675 8.01514C15.6885 8.01514 17.6025 6.98975 18.7476 5.60547ZM19.9438 8.37402C17.3975 8.37402 15.3125 9.9292 13.9795 9.9292C12.561 9.9292 10.7153 8.47656 8.49365 8.47656C4.27246 8.47656 0 11.9629 0 18.5254C0 22.627 1.57227 26.9507 3.5376 29.7363C5.2124 32.0947 6.68213 34.0259 8.80127 34.0259C10.8862 34.0259 11.8091 32.6416 14.4067 32.6416C17.0386 32.6416 17.6367 33.9917 19.9438 33.9917C22.2339 33.9917 23.7549 31.8896 25.2075 29.8218C26.814 27.4463 27.4976 25.1392 27.5146 25.0195C27.3779 24.9854 23.0029 23.1909 23.0029 18.1836C23.0029 13.8428 26.438 11.8945 26.6431 11.7407C24.3701 8.47656 20.9009 8.37402 19.9438 8.37402Z" fill="currentColor"/>
      </svg>
  )
}

const DownloadCTA = () => {
  return (
    <CallToAction>
      <ButtonElement href="/download">Download</ButtonElement>
      <DownloadInfo><AppleLogo/>For Mac and iPhone</DownloadInfo>
    </CallToAction>
  )
}

const CallToAction = styled.span`
    grid-column: col-start / span 12;
    margin: 0 auto;
    margin-bottom: ${props => props.theme.spacing[8]};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    ${props => props.theme.device.tablet} {
      margin-bottom: 0;
      grid-column: span 6;
      margin: 0;
      margin-top: 72px;
      text-align: right;
      flex-direction: row;
      gap: 24px;
    }
    ${props => props.theme.device.laptop} {
        grid-column: span 6;
        order: 1;
        margin: 0;
        margin-top: 72px;
        text-align: left;
    }
`
const DownloadInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    color: ${props => props.theme.color.textSecondary};
    font-size: ${props => props.theme.fontSize.regular};
    font-weight: 600;
    & svg {
        margin-top: -2px;
        scale: 0.7;
    }
`

export default DownloadCTA